@font-face {
  font-family: Roboto;
  src: url(/fonts/Roboto-Light.woff2);
  font-weight: lighter;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url(/fonts/Roboto-Regular.woff2);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url(/fonts/Roboto-Bold.woff2);
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url(/fonts/Roboto-Italic.woff2);
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Geomanist;
  src: url(/fonts/Geomanist-Medium.otf) format("opentype");
  font-display: swap;
}
@font-face {
  font-family: Geomanist;
  src: url(/fonts/Geomanist-Bold.otf) format("opentype");
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: Geomanist;
  src: url(/fonts/Geomanist-Medium-Italic.otf) format("opentype");
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Geomanist;
  src: url(/fonts/geomanist-regular-webfont.woff2);
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: Geomanist;
  src: url(/fonts/Geomanist-Light.otf) format("opentype");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: Double;
  src: url(/fonts/DoubleFeature20.ttf) format("truetype");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: Double;
  src: url(/fonts/DoubleFeature20.ttf) format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: Double;
  src: url(/fonts/DoubleFeature20.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

/* Variables*/
:root {
  --font-geomanist: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana,
    'sans-serif';
  --font-double: Double, var(--font-geomanist);

  --color-white: #ffffff;
  --color-lightest-blue: #f7f7ff;
  --color-secondary-light-blue: #f8f9fe;
  --color-black: #000000;
  --color-blue: #ecf0fd;
  --color-mid-blue: #e8edf7;
  --color-grey: #555160;
  --color-light-grey: #d9d9d9;
  --color-primary: #0000ff;
  --color-selected-off-white: #f5f7fe;
  --color-danger: #f4141c;
  --color-gradient: -webkit-linear-gradient(180deg, #f4111b 30%, #0402fd);
  --color-background-gradient: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 255, 0.1) 10%,
    rgba(244, 17, 27, 0.1) 50%,
    rgba(255, 255, 255, 0.1) 100%
  );
  --color-yellow: #ffca0b;
}

@layer components {
  /* Layout */
  .slice {
    display: flex;
    padding: 4rem 0;
    @media (min-width: 640px) {
      padding: 6rem 0;
    }
  }

  .slice__inner {
    @apply container flex flex-col items-center mx-auto lg:flex-row;
  }

  .title,
  .slice__title {
    @apply mb-4 text-2xl text-primary;
  }

  .subtitle,
  .slice__subtitle {
    @apply mb-4 text-xl font-bold text-black;
  }

  .slice__text {
    @apply mb-4 sm:text-lg;
  }

  .slice p,
  .slice ol {
    @apply mb-4 text-gray-600 sm:text-lg;
    color: var(--color-gray-50);
  }

  .slice p,
  .slice ol {
    @apply mb-4 text-gray-600 sm:text-lg;
    color: var(--color-gray-50);
  }

  .container {
    @apply mx-auto;
  }
}

.container {
  @apply mx-auto;
}

@media screen and (min-width: 1300px) {
  @supports (-webkit-hyphens: none) {
    .container {
      max-width: 90%;
    }
  }
}
@media screen and (min-width: 1536px) {
  @supports (-webkit-hyphens: none) {
    .container {
      max-width: 85%;
    }
  }
}

/* Headings */
.heading {
  @apply text-primary;
}

.heading--black {
  @apply text-black;
}

.heading--h1 {
  font-size: 4.063rem;
}
.heading--h2 {
  font-size: 1.563rem;

  @media screen and (min-width: 640px) {
    font-size: 3.313rem;
  }
}
.heading--h3 {
  font-size: 1.5rem;
}

.heading--h4 {
  font-size: 1.313rem;
}

.p-normal {
  font-size: 1.125rem;
  @media screen and (min-width: 640px) {
    font-size: 1.313rem;
  }
}

.p-small {
  font-size: 1.063rem;
}

.small-text {
  font-size: 1rem;
}

a {
  font-family: var(--font-geomanist);
  text-decoration: underline;
  color: var(--color-primary-blue);
}

/* infostep animation offsets */
.infoStep > div:nth-of-type(1) > .infoStep__circle:before {
  animation-delay: 0s;
}
.infoStep > div:nth-of-type(2) > .infoStep__circle:before {
  animation-delay: 0.4s;
}
.infoStep > div:nth-of-type(3) > .infoStep__circle:before {
  animation-delay: 0.8s;
}
.infoStep > div:nth-of-type(4) > .infoStep__circle:before {
  animation-delay: 1.2s;
}

.color--grey {
  color: var(--color-grey);
}

.color--primary {
  color: var(--color-primary);
}

.bg--blue {
  background-color: var(--color-blue);
}

.bg--lightest-blue {
  background-color: var(--color-lightest-blue);
}

.font-geomanist {
  font-family: var(--font-geomanist);
}

form .error {
  display: block;
  color: var(--colors-error);
  margin-top: 0.5rem;
  font-size: 1rem;
}

.has-sticky-checkout-footer #tidio-chat iframe {
  margin-bottom: 4rem !important;
}

.basket-contents {
  max-height: 24rem;
  overflow-y: scroll;
}

.radio-row {
  width: 150px;
  height: 62px;
  border-radius: 5px;
  padding: 1rem 3rem 1rem 1.5rem;
  display: flex;
  align-items: center;
  background: var(--color-blue) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 0 0 10px #7474c438;
}

.radio-row [type='radio']:not(.booleanInput) {
  margin-right: 1rem;
  transform: scale(1.5);
  appearance: none;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 3px #0c0c3b55;
  opacity: 1;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.radio-row [type='radio']:hover {
  cursor: pointer;
}

.radio-row [type='radio']:checked {
  background: var(--color-white) url('/icons/Ellipse.svg') 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: 45%;
}

.radio-row label,
.radio-row .radio-row__label {
  color: var(--colors-text-primary);
  font-family: var(--font-geomanist);
  opacity: 100% !important;
  font-weight: 300;
}

.radio-row input:checked + label,
.radio-row input:checked + .radio-row__label {
  font-weight: 500;
  margin-bottom: -4px;
}

.video-player video {
  background-color: transparent;
}

body {
  scroll-behavior: smooth;

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}
